import React from "react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Video = () => {
  const lang = "fo"

  return (
    <Layout lang={lang}>
      <SEO title="Høgra - Video" />
      <section className="video-page">
        <div className="container">
          <h2>
            Hygg, hvat vit kunnu
            <br />
            gera fyri teg:
          </h2>
          <div className="embed-container no-padding">
            <ReactPlayer
              url="https://player.vimeo.com/progressive_redirect/playback/925826133/rendition/1080p/file.mp4?loc=external&log_user=0&signature=1f610763dfcb72d585b90f4ae3e51373b00fcf5fda7f73f1d56f8c396db67b72"
              width="100%"
              heigth="100%"
              playing={true}
              controls={true}
              muted={true}
              loop={true}
              playsinline
            />
          </div>
          <h2>
            Bið um eitt tilboð!
            <br />
            Ring 22 33 66
            <br />
            ella skriva til hogni@hogra.fo
          </h2>
        </div>
      </section>
    </Layout>
  )
}

export default Video
